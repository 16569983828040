import { useRef } from "react";
import Top from "../../images/top.png";

const SmallImagesSlider = ({ data, setHoverIndex }) => {
  const elementRef = useRef(null);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className="md:max-w-[1800px] xl:max-w-full mx-auto hidden md:flex relative">
      <div className="flex mt-16">
        <button
          className="absolute max-lg:left-[10px] left-[9.2%] top-[50%] z-50 shadow-md"
          onClick={() =>
            handleHorizantalScroll(elementRef.current, 25, 50, -500)
          }
        >
          <img
            loading="lazy"
            className="rotate"
            src={Top}
            alt="left-arrow"
            width="42"
            height="42"
          />
        </button>

        <div className="img-container" ref={elementRef}>
          <div className="flex -ml-[73%] mr-[5%]">
            {data?.map((item, index) => (
              <div
                className="flex justify-center items-center lg:w-[500px] "
                key={item?.title + index}
              >
                <p
                  className="text-[25px] uppercase font-extrabold w-[50px] text-right mt-24 max-md:hidden"
                  style={{ transform: "rotate(-90deg)" }}
                >
                  {item?.title}
                </p>
                <div className="flex flex-col w-[400px]">
                  <img
                    loading="lazy"
                    className="rounded-rectangle-3-copy-4"
                    src={item?.image}
                    alt="images"
                    onMouseEnter={() => setHoverIndex(item?.image)}
                    onMouseLeave={() => setHoverIndex("")}
                    width={336}
                    height={228}
                  />
                  <div className="row-2 group flex justify-center">
                    <p className="texas-2 font-extrabold">{item?.title}</p>
                    <div className="shape-2-copy"></div>
                    <div
                      className={`text-left ml-5 text-[13px] -mt-2 text-white leading-[27.2px] font-normal text-font-plus-jakarta`}
                    >
                      {item?.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className="absolute max-lg:right-[10px] right-[20%] top-[50%] z-50 right-button shadow-md"
          onClick={() =>
            handleHorizantalScroll(elementRef.current, 25, 100, 500)
          }
        >
          <img
            loading="lazy"
            // className="top"
            src={Top}
            alt="right-arrow"
            width="42"
            height="42"
          />
        </button>
      </div>
    </div>
  );
};
export default SmallImagesSlider;
