import { useState } from "react";

const data = [
  {
    id: 1,
    title: "Which Nationals are you working with?",
    description:
      "We are working with Northsight Management, Brookstone Management, Guardian Asset Management, Single Source, MCS, NFR, Safeguard, Five Brothers, Cyprexx, MSI, etc.",
  },
  {
    id: 2,
    title: "How do I get paid for my services?",
    description:
      "Payment terms are usually defined in your contract with the property preservation company. Payments may be made on a per-job basis or at regular intervals (e.g., bi-weekly, monthly) upon submission of invoices.",
  },
  {
    id: 3,
    title: "What kind of references should I provide?",
    description:
      "Provide professional references from: - Previous clients who can speak to the quality of your work - Former employers or supervisors who can attest to your skills and reliability - Colleagues or industry professionals who can vouch for your experience and work ethic.",
  },
  {
    id: 4,
    title:
      "What are the typical working hours for property preservation contractors?",
    description:
      "Working hours can vary depending on the job requirements and deadlines. While some tasks can be completed during regular business hours, others might require flexibility, including weekends and evenings.",
  },
  {
    id: 5,
    title: "How often should I expect to receive assignments?",
    description:
      "The frequency of assignments can vary based on the demand for property preservation services in your area and the company's workload. Discuss expectations with the company during the onboarding process.",
  },
  {
    id: 6,
    title:
      "What qualifications do I need to become a property preservation contractor?",
    description: "Qualifications may include: ",
  },
];
const Section7 = () => {
  const [active, setActive] = useState();
  return (
    <div className="l-unconstrained md:mx-auto">
      <div className="l-constrained md:max-w-[900px] px-10">
        <p className="faq font-extrabold">FAQ</p>
        <p className="text-38 font-extrabold">
          <span className="colorcc0001">Frequently </span>Asked Questions
        </p>
        <div className="mb-16" />
        {data.map((item, index) => (
          <div
            className={`${active === index ? " pt-5" : ""}`}
            key={item?.id + index}
          >
            <div
              className={`cursor-pointer group ${
                active === index ? "row-19 pt-10" : "row-10"
              }`}
              onClick={() => setActive(index === active ? "" : index)}
            >
              <div
                className={`text-left text-darkGrey w-full  ${
                  active === index ? "col-13" : "text-41"
                }`}
              >
                <span className="flex gap-5 justify-between items-center font-normal">
                  {item.title}
                  {active === index ? (
                    <p className="text-45 -mt-2">-</p>
                  ) : (
                    <p className="text-45 -mt-2">+</p>
                  )}
                </span>
                <div className="block">
                  {item.id === 3 ? (
                    <div
                      className={`text-left mt-6 text-[16.2px] text-darkGrey leading-[27.2px] font-extrabold  text-font-plus-jakarta ${
                        active === index ? "block" : "hidden"
                      }`}
                    >
                      Provide professional references from:
                      <br /> - Previous clients who can speak to the quality of
                      your work
                      <br />
                      - Former employers or supervisors who can attest to your
                      skills and reliability
                      <br />- Colleagues or industry professionals who can vouch
                      for your experience and work ethic.
                    </div>
                  ) : item.id === 6 ? (
                    <div
                      className={`text-left mt-6 text-[16.2px] text-darkGrey leading-[27.2px] font-extrabold  text-font-plus-jakarta ${
                        active === index ? "block" : "hidden"
                      }`}
                    >
                      Qualifications may include: <br />- Relevant experience in
                      property maintenance or construction
                      <br /> - Required licenses and certifications as per state
                      and local regulations <br />- Adequate insurance coverage
                      (e.g., general liability, workers' compensation)
                      <br /> - Reliable transportation and necessary tools and
                      equipment
                    </div>
                  ) : (
                    <div
                      className={`text-left mt-6 text-[16.2px] text-darkGrey leading-[27.2px] font-extrabold  text-font-plus-jakarta ${
                        active === index ? "block" : "hidden"
                      }`}
                    >
                      {item?.description}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Section7;
