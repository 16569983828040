const BASE_URL =
  process.env.REACT_APP_BREVO_SERVER_API ||
  "https://api.brevo.com/v3/smtp/email";

const API_URL =
  process.env.REACT_APP_BREVO_API ||
  "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";

const sendBrevoEmail = async (method, header, body) => {
  return await fetch(BASE_URL, {
    method: method,
    headers: {
      accept: "application/json",
      "api-key": API_URL,
      ...header,
    },
    body: JSON.stringify({
      sender: {
        name: "Lopez Property Preservation",
        email:
          process.env.REACT_APP_BREVO_SENDER ||
          "noreply@lopezpropertypreservation.com",
      },
      to: [...body.to],
      subject: body?.subject,
      htmlContent: body?.body,
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
};
export default sendBrevoEmail;
