import Image1 from "../../images/whatsappimage2024-05-21.jpg";
import Image2 from "../../images/whatsappimage2024-05-21at_2.jpg";
import Image6 from "../../images/whatsappimage2024-05-21at_3.jpg";
import Image7 from "../../images/whatsappimage2024-05-21at_4.jpg";
import Image8 from "../../images/whatsappimage2024-05-21at_5.jpg";
import Image3 from "../../images/whatsappimage2024-05-21at_6.jpg";
import Image4 from "../../images/whatsappimage2024-05-21at_7.jpg";
import Image5 from "../../images/whatsappimage2024-05-21at_8.jpg";

const Section2 = () => {
  return (
    <div className="col-11 flex justify-center items-center md:mx-auto">
      <div className="l-constrained-4 md:max-w-[1600px]">
        <div className="row-16 group flex w-full justify-center pl-5 items-center">
          <div className="shape-4-copy float-left"></div>
          <p className="text-9 font-extrabold text-center">
            National Vendors we've worked with
          </p>
          <div className="shape-4 float-right"></div>
        </div>
        <div className="row-17 no-space-between-inline-blocks flex justify-center items-center max-md:py-5 max-md:px-5 max-md:gap-5 md:gap-10 flex-wrap">
          <img
            className="Whatsappimage"
            src={Image1}
            alt="image1"
            loading="lazy"
            width="162"
            height="116"
          />
          <img
            className="Whatsappimage"
            src={Image2}
            alt="image2"
            loading="lazy"
            width="126"
            height="126"
          />
          <img
            className="Whatsappimage"
            src={Image3}
            alt="image3"
            loading="lazy"
            width="72"
            height="51"
          />
          <img
            className="Whatsappimage"
            src={Image4}
            alt="image4"
            loading="lazy"
            width="168"
            height="88"
          />
          <img
            className="Whatsappimage"
            src={Image5}
            alt="image5"
            loading="lazy"
            width="96"
            height="96"
          />
          <img
            className="Whatsappimage"
            src={Image6}
            alt="image6"
            loading="lazy"
            width="137"
            height="50"
          />
          <img
            className="Whatsappimage"
            src={Image7}
            alt="image7"
            loading="lazy"
            width="171"
            height="65"
          />
          <img
            className="Whatsappimage"
            src={Image8}
            alt="image8"
            loading="lazy"
            width="151"
            height="54"
          />
        </div>
      </div>
    </div>
  );
};
export default Section2;
