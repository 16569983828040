import { useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Context } from "../../context/Context";
import Click from "../../images/click.png";
import Debris from "../../images/icons/debris.png";
import Rectangle3 from "../../images/icons/debris_2.jpg";
import Rectangle1 from "../../images/icons/Lawn.jpg";
import Gardener from "../../images/icons/lawn.png";
import PropertyInspection from "../../images/icons/property_inspection.png";
import Rectangle2 from "../../images/icons/property_inspection_2.jpg";
import Repairs from "../../images/icons/repairs.png";
import Rectangle4 from "../../images/icons/repairs_and_renovations_1.jpg";
import Securing from "../../images/icons/Securing.png";
import Rectangle6 from "../../images/icons/Securing_2.jpg";
import Snowflake3 from "../../images/icons/Wint.png";
import Rectangle5 from "../../images/icons/Winterization_2.jpg";
import Top from "../../images/top.png";

const data = [
  {
    id: 1,
    title: "Lawn/Yard Maintenance",
    image: Gardener,
    bgImage: Rectangle1,
    description:
      "Lawn and yard maintenance are crucial for property preservation, enhancing aesthetic appeal, value, safety, compliance, and longevity, with services including seasonal care, clippings, waste removal, sweeping, and shrub trimming.",
  },
  {
    id: 2,
    title: "Property Inspection",
    image: PropertyInspection,
    bgImage: Rectangle2,
    description:
      "Property inspections are essential in property preservation, involving visual checks of the building's structure, systems, and surroundings, along with occupancy status, damage reports, safety recommendations, signage, and detailed video records.",
  },
  {
    id: 3,
    title: "Debris Removal",
    image: Debris,
    bgImage: Rectangle3,
    description:
      "Debris removal prevents health hazards from waste accumulation, with our team dedicated to clearing interior, exterior, and hazardous debris according to client requirements and investor specifications.",
  },
  {
    id: 4,
    title: "Repairs and Renovations",
    image: Repairs,
    bgImage: Rectangle4,
    description:
      "Our services include addressing and repairing damages to maintain property integrity and prevent small issues from escalating, handling everything from minor fixes to major renovations, and focusing on preventative maintenance to keep the property in excellent condition during vacancy or transition.",
  },
  {
    id: 5,
    title: "Winterization",
    image: Snowflake3,
    bgImage: Rectangle5,
    description:
      "We prepare properties for winter by protecting plumbing systems from freezing and preventing costly water damage, following strict protocols based on the type of heating.",
  },
  {
    id: 6,
    title: "Securing",
    image: Securing,
    bgImage: Rectangle6,
    description:
      "Our team ensures properties are securely locked and boarded up to prevent unauthorized access and vandalism, offering services such as boarding, lock changes, re-keying, lockbox installation, and window guards.",
  },
];
const Section4 = () => {
  const [active, setActive] = useState(0);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    setActive(context);
  }, [context]);

  return (
    <div className="row-18 flex justify-center items-center md:mx-auto">
      <div className="container lg:max-w-[1600px] lg:px-36">
        <div className="flex max-lg:flex-wrap justify-around gap-5">
          <div className="max-md:w-full lg:w-[50%] md:w-[80%] lg:ml-12">
            <p className="services text-center lg:text-left max-md:px-4 lg:pl-1 hidden">
              Services
            </p>
            <p className="text-18 text-center lg:text-left font-extrabold">
              <span className="colorcc0001 max-md:pl-3">Our </span>
              SERVICES
            </p>
            <div
              className={`lg:pl-1 lg:mr-20 max-md:px-4 text-center lg:text-left mt-6 text-[18px] text-darkGrey leading-[27.2px] font-normal  text-font-plus-jakarta `}
            >
              Our company offers comprehensive property preservation services
              designed to protect and maintain your valuable assets. We offer a
              specialized range of property preservation services, including:
            </div>

            <div className="flex-wrap mt-5 ml-8 mr-12 max-lg:items-center max-lg:justify-center hidden md:flex">
              {data.map((item, index) => (
                <div
                  className="flex relative max-md:w-full md:w-[45%] h-[125px] drop-shadow-lg cursor-pointer"
                  key={item?.id + index}
                >
                  {index === active && (
                    <div className="rounded-rectangle-6-copy-4 mt-[4.5px]"></div>
                  )}
                  <div
                    className="bg-white flex m-3 w-full px-4 py-8 z-10 rounded-xl text-left items-center"
                    onClick={() => {
                      setActive(index);
                      setContext(index);
                    }}
                  >
                    <img
                      loading="lazy"
                      className="gardener"
                      src={item?.image}
                      alt="hardener"
                      width="42"
                      height="42"
                    />
                    {index === active && (
                      <img
                        loading="lazy"
                        className={`remodeling-copy absolute ${
                          index === 0
                            ? "top-14 right-5"
                            : index === 1
                            ? "top-14 left-16"
                            : index === 2
                            ? "top-16 right-16"
                            : index === 3
                            ? "top-16 left-16"
                            : index === 4
                            ? "top-14 left-28"
                            : "top-14 left-16"
                        } `}
                        src={Click}
                        alt={"image" + index}
                        width="30"
                        height="30"
                      />
                    )}
                    <div className="text-20 leading-5 text-black text-font-plus-jakarta font-bold">
                      {item?.title}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="section4 block md:hidden mt-10">
              <Carousel
                showStatus={false}
                infiniteLoop
                selectedItem={active}
                showThumbs={false}
                showIndicators={false}
                renderArrowPrev={(clickHandler, hasPrev) =>
                  hasPrev && (
                    <button onClick={clickHandler}>
                      <img
                        loading="lazy"
                        className="rotate"
                        src={Top}
                        alt="top"
                        width="42"
                        height="42"
                      />
                    </button>
                  )
                }
                renderArrowNext={(clickHandler, hasNext, labelNext) =>
                  hasNext && (
                    <button onClick={clickHandler}>
                      <img
                        loading="lazy"
                        // className="top"
                        src={Top}
                        alt="top"
                        width="42"
                        height="42"
                      />
                    </button>
                  )
                }
              >
                {data?.map((item, index) => (
                  <div
                    className="flex relative drop-shadow-lg cursor-pointer mx-2"
                    key={item?.id + index}
                  >
                    {index === active && (
                      <div className="rounded-rectangle-6-copy-4 mt-[5px]"></div>
                    )}
                    <div
                      className="bg-white flex m-3 w-full px-5 py-7 z-10 rounded-xl text-left items-center justify-center"
                      onClick={() => setActive(index)}
                    >
                      <div className="w-[50px] h-[50px] mr-5">
                        <img
                          src={item?.image}
                          alt={item?.title}
                          loading="lazy"
                        />
                      </div>
                      {index === active && (
                        <img
                          loading="lazy"
                          className={`remodeling-copy-1 absolute ${
                            index === 0
                              ? "top-16 right-5"
                              : index === 1
                              ? "top-16 left-16"
                              : index === 2
                              ? "top-20 right-16"
                              : index === 3
                              ? "top-20 left-16"
                              : index === 4
                              ? "top-16 left-28"
                              : "top-16 left-16"
                          } `}
                          src={Click}
                          alt=""
                          width="20"
                          height="20"
                        />
                      )}
                      <div className="text-20 leading-5 text-black font-bold">
                        {item?.title}
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="wrapper-8 relative max-md:w-full lg:w-[50%] md:w-[80%]">
            <div className="rounded-rectangle-11"></div>
            <div className="rectangle-6-holder lg:h-[85%] flex justify-center items-end relative max-lg:h-[500px]">
              <img
                loading="lazy"
                src={data?.[active]?.bgImage}
                alt="bg"
                className="absolute top-0 bottom-0 left-0 right-0 w-full h-full lg:w-[800px] rounded-3xl object-cover"
              />
              <div className="col-14">
                <div className="row-24 group">
                  <div className="col-19">
                    <p className="text-25 text-left font-extrabold">
                      <span className="colorcc0001">
                        {data?.[active]?.title?.split(" ")?.[0]}{" "}
                      </span>
                      {data?.[active]?.title?.split(" ")?.[1]
                        ? data?.[active]?.title?.split(" ")?.[1] +
                          " " +
                          (data?.[active]?.title?.split(" ")?.[2]
                            ? data?.[active]?.title?.split(" ")?.[2]
                            : "")
                        : ""}
                    </p>
                    <div
                      className={`text-left mt-2 text-[16px] text-darkGrey leading-[25.2px] font-normal  text-font-plus-jakarta `}
                    >
                      {data?.[active]?.description}
                    </div>
                  </div>
                  <img
                    loading="lazy"
                    className="remodeling-copy hidden"
                    src={data?.[active]?.image}
                    alt="copy"
                    width="74"
                    height="74"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section4;
