import RoundedRectangle2 from "../../images/icons/about_Us.jpg";
const Section1 = () => {
  return (
    <div className="mx-auto ">
      <div className="row-15 group flex md:px-16 gap-8 lg:max-w-[1600px] lg:mx-auto max-lg:flex-col mt-10 max-md:mt-5 max-lg:justify-center max-lg:items-center lg:pb-16">
        <div className="wrapper-6 lg:w-[50%] md:w-[80%] max-md:w-full max-lg:flex max-lg:justify-center lg:mt-12 max-lg:items-center">
          <img
            loading="lazy"
            className="rounded-rectangle-2 h-[500px] lg:h-[100%] object-cover mt-2"
            src={RoundedRectangle2}
            alt="rounded-rectangle2"
            style={{ objectPosition: "center" }}
          />
          <div className="col-2">
            <p className="text-white text-[43px] max-md:text-[20px] font-extrabold text-left">
              09+
            </p>
            <div className="text-white text-[20px] max-md:text-[15px] font-extrabold mt-3 leading-8 text-left">
              Years Of Experience
            </div>
          </div>
          <div className="col-3">
            <p className="text-white text-[43px]  max-md:text-[20px]  font-extrabold text-left">
              300,000+
            </p>
            <div className="text-white text-[20px] max-md:text-[15px] font-extrabold mt-3 leading-8 text-left">
              Processed Work Orders
            </div>
          </div>
        </div>
        <div className="col-10 lg:w-[50%] md:w-[80%] max-md:w-full lg:pl-24 max-md:px-5 flex flex-col max-lg:justify-center max-lg:items-center pt-8 max-md:mt-5">
          <p className="text-14 text-left max-md:ml-[2px] font-extrabold">
            About Us
          </p>
          <div className="lg:w-[505px] max-lg:w-[100%]">
            <p className="text-[57px] max-md:text-[32px] text-center lg:text-left text-black font-extrabold mt-3 uppercase leading-10 md:leading-[61.2px]">
              <span className="text-red">About </span>Lopez Property care
            </p>
            <div
              className={`lg:text-left text-center md:mt-6 max-md:mt-7 text-[18px] text-darkGrey leading-7 md:leading-[27.2px] font-normal text-font-plus-jakarta `}
            >
              <span className="text-red text-font-plus-jakarta font-semibold max-md:text-[18px] text-[20px]">
                Welcome to Lopez Property Care,
              </span>
              with over 9 years of experience and 300,000+ work orders
              completed, we have built a reputation for maintaining properties
              to the highest standards across the United States.
              <div className="pt-4"></div>
              We started with a mission to provide trusted, exceptional property
              care, continually refining our processes to ensure meticulous
              preservation.
              <div className="pt-4"></div>
              Our unwavering commitment to excellence ensures property value
              preservation and a safe, well- maintained environment, using
              state-of-the-art equipment and industry best practices for
              reliable services.
            </div>
          </div>
          <div className="rounded-rectangle-1-copy-3-holder hidden">
            Learn More About Us
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section1;
