import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import California from "../../images/icons/California.jpg";
import Florida from "../../images/icons/Florida.jpg";
import Texas from "../../images/icons/Texas.jpg";
import rectangle_3 from "../../images/rectangle_3.jpg";
import Top from "../../images/top.png";
import SmallImagesSlider from "../SmallImagesSlider/SmallImagesSlider";

const data = [
  {
    id: 2,
    title: "Texas",
    description:
      "Our dedicated team handles a wide array of services, including essential tasks like winterization.",
    image: Texas,
  },
  {
    id: 3,
    title: "Florida",
    description:
      "Specializing in maintaining beautiful lawns and offering comprehensive property preservation solutions.",
    image: Florida,
  },
  {
    id: 1,
    title: "California",
    description:
      "Our contractors are experts in a wide range of services, including repairs and rehab and all aspects of property preservation.",
    image: California,
  },
  {
    id: 2,
    title: "Texas",
    description:
      "Our dedicated team handles a wide array of services, including essential tasks like winterization.",
    image: Texas,
  },
  {
    id: 3,
    title: "Florida",
    description:
      "Specializing in maintaining beautiful lawns and offering comprehensive property preservation solutions.",
    image: Florida,
  },
  {
    id: 1,
    title: "California",
    description:
      "Our contractors are experts in a wide range of services, including repairs and rehab and all aspects of property preservation.",
    image: California,
  },
  {
    id: 2,
    title: "Texas",
    description:
      "Our dedicated team handles a wide array of services, including essential tasks like winterization.",
    image: Texas,
  },
  {
    id: 3,
    title: "Florida",
    description:
      "Specializing in maintaining beautiful lawns and offering comprehensive property preservation solutions.",
    image: Florida,
  },
];

const Section6 = () => {
  const [hoverIndex, setHoverIndex] = useState("");

  return (
    <div className="l-constrained-6 col-12 w-full relative z-50">
      <img
        loading="lazy"
        src={hoverIndex === "" ? rectangle_3 : hoverIndex}
        className={`w-full absolute top-0 bottom-0 right-0 left-0 md:h-[700px] lg:h-[933px] object-cover max-md:h-[680px] ${
          hoverIndex === "" ? "" : "filter-gray"
        }`}
        alt="filter"
      />
      <div className="flex flex-col lg:max-w-[1200px] mx-auto">
        <p className="contractors">COVERAGE</p>
        <div className="row-23 group flex">
          <p className="text-28">We Worked With by State</p>
          <div className="shape-3"></div>
          <div
            className={`max-md:text-center md:px-28 lg:px-0 lg:text-left text-[18px] mt-5 text-white leading-[27.2px] font-normal text-font-plus-jakarta lg:mr-32`}
          >
            <span className="text-[#EA7677] text-font-plus-jakarta font-semibold text-[22px]">
              Our comprehensive
            </span>
            range of services and our commitment to excellence have earned us a
            reputation as a trusted partner for property care.
          </div>
        </div>
      </div>

      <SmallImagesSlider data={data} setHoverIndex={setHoverIndex} />

      <div className="section6 block md:hidden mt-10 w-[90%]">
        <Carousel
          width={"100%"}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
            hasPrev && (
              <button onClick={clickHandler} className="shadow-md">
                <img
                  loading="lazy"
                  className="rotate"
                  src={Top}
                  alt="left-arrow"
                  width="42"
                  height="42"
                />
              </button>
            )
          }
          renderArrowNext={(clickHandler, hasNext, labelNext) =>
            hasNext && (
              <button onClick={clickHandler} className="shadow-md">
                <img
                  loading="lazy"
                  src={Top}
                  alt="right-arrow"
                  width="42"
                  height="42"
                />
              </button>
            )
          }
        >
          {data.map((item, index) => (
            <div className="" key={item?.title + index}>
              <div className="flex flex-col mx-2">
                <img
                  loading="lazy"
                  className="z-10 px-2 rounded-[35px]"
                  src={item?.image}
                  alt="images"
                  width={100}
                  onMouseEnter={() => setHoverIndex(item?.image)}
                  onMouseLeave={() => setHoverIndex("")}
                />
                <div className="flex relative justify-center gap-5 flex-wrap bg-red -mt-24 pt-28 rounded-3xl">
                  <span className="text-white">{item?.title}</span>
                  <span
                    className={`text-center mx-5 mb-8 text-[12px] text-white leading-[22.2px] font-normal text-font-plus-jakarta `}
                  >
                    {item?.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default Section6;
