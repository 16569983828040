const emailTemplateContactUs = (name, email, subject, message) => {
  return `<html>
         <head>
         </head>
         <body>
         <p>Hello Admin,</p>

         <div style="margin-left:20px; margin-bottom:20px">
         <p>A new contact us form has been submitted on the website.</p>
         <p>Details:</p>
         <ul>
         <li>Name: ${name}</li>
         <li>Email: ${email}</li>
         <li>Subject: ${subject}</li>
         <li>Message: ${message}</li>
         <li>Date: ${new Date()}</li>
         </ul>
         </div>
         <p>Best regards,</p>
         <p  style="margin-top:-10px;">Lopez Property Preservation Website</p>
         </body>
         </html>
         `;
};

const emailTemplateJoinUs = (
  businessName,
  contactName,
  email,
  address,
  phone,
  coverageArea,
  certifications
) => {
  return `<html>
         <head>
         </head>
         <body>
         <p>Hello Admin,</p>

         <div style="margin-left:20px; margin-bottom:20px">
         <p>A new join now form has been submitted on the website.</p>
         <p>Details:</p>
         <ul>
         <li>Company Name: ${businessName}</li>
         <li>Full Name: ${contactName}</li>
         <li>Email: ${email}</li>
         <li>Phone: <a href="tel:${phone}">${phone}</a></li>
         <li>Address: ${address}</li>
         <li>Coverage Area: ${coverageArea}</li>
         <li>Previous Experience: ${certifications}</li>
         <li>Date: ${new Date()}</li>
         </ul>
         </div>
         <p>Best regards,</p>
         <p  style="margin-top:-10px;">Lopez Property Preservation Website</p>
         </body>
         </html>
         `;
};
export { emailTemplateContactUs, emailTemplateJoinUs };
