import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomFooter from "./components/CustomFooter/CustomFooter.js";
import CustomHeader from "./components/CustomHeader/CustomHeader.js";
import { Context } from "./context/Context.js";
import HomePage from "./pages/HomePage/HomePage.js";
import "./css/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function App() {
  const [context, setContext] = useState(0);
  return (
    <Context.Provider value={[context, setContext]}>
      <Router>
        <div className="App">
          <div className="">
            <CustomHeader />
            <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
            </Routes>
            <CustomFooter />
            <ToastContainer />
          </div>
        </div>
      </Router>
    </Context.Provider>
  );
}

export default App;
