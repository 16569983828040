import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import LoneWorkerSafety from "../../images/icons/join_our_team.png";
import LoneWorkerSafety1 from "../../images/icons/join_our_team_1.png";
import JoinNow from "../../images/join_now.png";
import sendBrevoEmail from "../../apis/email";
import { emailTemplateJoinUs } from "../../utils/template";

const Section5 = () => {
  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [coverageArea, setCoverageArea] = useState("");
  const [phone, setPhone] = useState("");
  const [certifications, setCertifications] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  function isValidPhone(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = async () => {
    if (
      businessName !== "" &&
      contactName !== "" &&
      email !== "" &&
      address !== "" &&
      phone !== "" &&
      coverageArea !== "" &&
      certifications !== ""
    ) {
      if (!isValidEmail(email)) {
        toast("Email is invalid! ");
      } else if (!isValidPhone(phone)) {
        toast("Phone number is invalid");
      } else {
        await sendBrevoEmail(
          "POST",
          {},
          {
            to: [
              {
                email:
                  process.env.REACT_APP_BREVO_RECEIVER ||
                  "April@lopezpropertycare.com",
                name: "Lopez Property Preservation Admin",
              },
            ],
            subject: "New Form Submission Received",
            body: emailTemplateJoinUs(
              businessName,
              contactName,
              email,
              address,
              phone,
              coverageArea,
              certifications
            ),
          }
        );
        toast("Successfully submit!");
        setPhone("");
        setEmail("");
        setContactName("");
        setAddress("");
        setCertifications("");
        setCoverageArea("");
        setBusinessName("");
        setIsOpen(false);
      }
    } else if (
      businessName === "" &&
      contactName === "" &&
      email === "" &&
      phone === ""
    ) {
      toast("All field is Required!");
    } else if (businessName === "") {
      toast("Business Name field is Required!");
    } else if (contactName === "") {
      toast("Contact Name field is Required!");
    } else if (address === "") {
      toast("Address field is Required!");
    } else if (email === "") {
      toast("Email field is Required!");
    } else if (phone === "") {
      toast("Phone field is Required!");
    } else if (coverageArea === "") {
      toast("Coverage Area field is Required!");
    } else if (certifications === "") {
      toast("Previous Experience field is Required!");
    }
  };

  return (
    <div className="flex my-10 justify-center items-center md:mx-auto md:max-w-[1600px] w-full max-lg:flex-col h-full lg:pb-36 ">
      <div className="lg:w-[60%] lg:pt-16 md:w-[100%] max-md:px-10 md:pl-32 relative z-10 w-full flex justify-center items-center max-lg:justify-center max-lg:items-center">
        <img
          className="h-full z-10 w-full"
          src={LoneWorkerSafety}
          alt="safety"
          loading="lazy"
        />

        <img
          loading="lazy"
          style={{ transform: "rotate(-18deg)" }}
          className="lg:w-[200px] absolute lg:left-[22%] top-[20%] max-md:left-[12%] max-md:w-[80px] max-md:h-[80px]"
          src={LoneWorkerSafety1}
          alt="safety"
        />
      </div>
      <div className="lg:w-[60%] md:w-[80%] lg:text-left flex flex-col max-lg:text-center max-lg:justify-center  max-lg:items-center  w-full max-md:mt-5 max-md:pl-10 -ml-14">
        <div className="lg:w-[700px] max-lg:w-full  font-extrabold">
          <p className="text-33 md:mt-8 max-md:mt-3 lg:whitespace-nowrap">
            <span className="colorcc0001">Are You </span>A Contractor ?
          </p>
          <p className="text-34 lg:mt-3 max-lg:mt-2 md:mb-8">
            Lets Work Together
          </p>
          <div
            className={`max-md:mt-4 lg:text-left max-md:text-center text-[18px] text-darkGrey leading-[27.2px] font-normal text-font-plus-jakarta `}
          >
            Be a part of our dynamic and dedicated team at Lopez Property
            Preservation, where your skills and passion for property care can
            make a significant impact. We offer a supportive and growth-
            oriented environment, providing opportunities for professional
            development and career advancement. Whether you're an experienced
            professional or just starting out, we value your contribution and
            commitment to excellence in property preservation. Join us and help
            shape the future of property maintenance and security.
          </div>
        </div>
        <div
          className="rounded-rectangle-1-copy-4-holder mt-10 cursor-pointer  font-extrabold"
          onClick={() => openModal()}
        >
          Apply now
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <div className="md:w-[500px] max-md:w-[300px] ">
          <img
            alt="JoinNow"
            src={JoinNow}
            className="w-[150px] mt-2"
            loading="lazy"
          />
          <div className="overflow-auto md:max-h-[500px] mt-2 mb-5">
            <div className="flex flex-wrap">
              <div className="flex flex-col flex-1 md:mr-5">
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Company Name"
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
              <div className="flex flex-col flex-1">
                <input
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  placeholder="Full Name "
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address "
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <input
                value={phone}
                type="number"
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <input
                value={coverageArea}
                type="text"
                onChange={(e) => setCoverageArea(e.target.value)}
                placeholder="Coverage Area"
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                value={certifications}
                type="text"
                onChange={(e) => setCertifications(e.target.value)}
                placeholder="Previous Experience"
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
          </div>
          <div className="flex gap-5 justify-end ">
            <button
              className="rounded-rectangle-1-copy-4-holder-light-form bg-slate-400 font-plus h-[50px]"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              className="rounded-rectangle-1-copy-4-holder-form font-plus h-[50px]"
              onClick={() => onSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Section5;
